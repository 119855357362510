import {
  FreeCamera,
  Vector3,
  HemisphericLight,
  MeshBuilder,
  WebXRDefaultExperience,
  Color3,
  PhotoDome,
  StandardMaterial,
  WebXRFeatureName,
} from '@babylonjs/core';
import { Link } from 'gatsby';
import React, { useState, useCallback } from 'react';
import '../assets/stylesheets/pages/babylon.scss';
import '../assets/stylesheets/pages/babylon-xr-basics.scss';
import BabylonScene from '../components/BabylonScene/BabylonScene';

let box;

export default function BabylonXRBasics() {
  const [rotationSpeed, setRotationSpeed] = useState(10);

  const onSceneReady = useCallback(async (scene) => {
    // This creates and positions a free camera (non-mesh)
    const camera = new FreeCamera('camera1', new Vector3(0, 5, -10), scene);
    // This targets the camera to scene origin
    camera.setTarget(Vector3.Zero());
    const canvas = scene.getEngine().getRenderingCanvas();
    // This attaches the camera to the canvas
    camera.attachControl(canvas, true);
    // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
    const light = new HemisphericLight('light', new Vector3(0, 1, 0), scene);
    // Default intensity is 1. Let's dim the light a small amount
    light.intensity = 0.7;
    // Our built-in 'box' shape.
    box = MeshBuilder.CreateBox('box', { size: 2 }, scene);
    // Move the box upward 1/2 its height
    box.position.y = 1;

    const dome = new PhotoDome(
      'testdome',
      '/3D/XRBasic/360photo.jpg',
      {
        resolution: 32,
        size: 1000,
      },
      scene
    );

    dome.setDirection(new Vector3(0, -0.25, 0));

    const environmentOptions = {
      groundColor: Color3.Black(),
      groundYBias: 0.1,
      enableGroundMirror: true,
    };

    const environment = scene.createDefaultEnvironment(environmentOptions);

    const middleGround = MeshBuilder.CreateGround(
      'middleGround',
      { width: 15, height: 15 },
      scene
    );
    const higherGround = MeshBuilder.CreateGround(
      'higherGround',
      { width: 20, height: 6 },
      scene
    );
    const lowerGround = MeshBuilder.CreateGround(
      'lowerGround',
      { width: 10, height: 10 },
      scene
    );

    const higherGroundMaterial = new StandardMaterial('higherGround', scene);
    higherGroundMaterial.emissiveColor = new Color3(1, 0, 0);
    higherGroundMaterial.backFaceCulling = false;
    const middleGroundMaterial = new StandardMaterial('middleGround', scene);
    middleGroundMaterial.emissiveColor = new Color3(0, 1, 0);
    const lowerGroundMaterial = new StandardMaterial('lowerGround', scene);
    lowerGroundMaterial.emissiveColor = new Color3(0, 0, 1);

    higherGround.material = higherGroundMaterial;
    lowerGround.material = lowerGroundMaterial;
    middleGround.material = middleGroundMaterial;

    higherGround.position.x = 10;
    higherGround.position.y = 4;
    higherGround.position.z = 4;

    lowerGround.position.x = -10;
    lowerGround.position.y = -10;
    lowerGround.position.z = -10;

    // Initialize Default WebXR Helper.
    const defaultXRExperience = await WebXRDefaultExperience.CreateAsync(
      scene,
      { floorMeshes: [higherGround, middleGround, lowerGround] }
    );

    console.log(defaultXRExperience);

    const { featuresManager } = defaultXRExperience.baseExperience;

    featuresManager.enableFeature(
      WebXRFeatureName.TELEPORTATION,
      'stable' /* or latest */,
      {
        xrInput: defaultXRExperience.input,
        // add options here
        floorMeshes: [higherGround, middleGround, lowerGround],
      }
    );
    // flip to false if you only want paraboloic.
    defaultXRExperience.teleportation.straightRayEnabled = true;
    if (!defaultXRExperience.baseExperience) {
      // no xr support
      console.log("Whoa boy, XR didn't initialize properly");
    } else {
      // all good, ready to go
    }
  });
  /**
   * Will run on every frame render.  We are spinning the box on y-axis.
   */
  const onRender = useCallback(
    (scene) => {
      if (box !== undefined) {
        const deltaTimeInMillis = scene.getEngine().getDeltaTime();
        const rpm = rotationSpeed;
        box.rotation.y += (rpm / 60) * Math.PI * 2 * (deltaTimeInMillis / 1000);
      }
    },
    [rotationSpeed]
  );

  return (
    <div>
      <BabylonScene
        antialias
        onSceneReady={onSceneReady}
        onRender={onRender}
        id="my-canvas"
      />

      <div className="button-container">
        <p>Rotation Speed</p>
        <p className="speed-control">
          <button
            type="button"
            className="button button--slower"
            onClick={() => setRotationSpeed((prevSpeed) => prevSpeed - 1)}
          >
            -
          </button>
          <span className="rotation-speed">{rotationSpeed}</span>
          <button
            type="button"
            className="button button--faster"
            onClick={() => setRotationSpeed((prevSpeed) => prevSpeed + 1)}
          >
            +
          </button>
        </p>
        <p>
          <Link to="/babylonjs-react-gatsby">
            Read a full description of how to add and control a babylonjs scene
            from a React page.
          </Link>
        </p>
      </div>
    </div>
  );
}
